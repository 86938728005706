.overlay {

    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    background-color: rgba(72, 73, 74, 0.5);
    z-index: 11;
}

.resize-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.resize-container .resize-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
}

.horizontal-resizer {
  width: 2px;
  cursor: col-resize;
  background-color: var(--primary-color);
  /* Prevent the browser's built-in drag from interfering */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

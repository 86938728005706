.header {
  width: 100%;
  min-height: 5.7143rem;
  border-bottom: 2px solid #363838;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-right: 3.5714rem;
  position: relative;
}


.header .header-menu-btn {
  display: flex;
  justify-content: center;
  width: 5.7143rem;
  align-items: center;
  cursor: pointer;
}

.header .header-menu-btn:hover {
 
  fill: var(--primary-color); 
}

.header .header-menu-btn:hover rect {
  fill: var(--primary-color) !important; 
}

.header .header-app-logo {
  text-align: left;
  font: normal normal 600 24px/35px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-left: 0.9375rem;
  cursor: pointer;
}

.header .header-app-logo:hover {
  color: var(--primary-color);
}

.header .header-space {
  flex-grow:1
}

.header .header-project {
  color: var(--primary-color);
  justify-content: center;
  display: flex ;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
}

.header .header-project svg{
  margin-top: -1px;
  height: 11px;
}

.header .header-user-actions {
  padding: 0.5rem;
}




.popup-container.add-page-popup {
  display: flex;
  flex-direction: column;
  min-width: 95vw;
  height: 90vh;
}

.popup-container.add-page-popup .add-page-popup-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.popup-container.add-page-popup .add-page-popup-header .act-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.popup-container.add-page-popup
  .add-page-popup-header
  .act-btns
  .button.primary {
  margin-left: 1rem;
  height: 2.5rem;
}

.popup-container.add-page-popup .line-separator {
  margin-bottom: 1rem;
}

.popup-container.add-page-popup .form-field {
  width: 20rem;
}

.popup-container.add-page-popup .mk-editor {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.popup-container.add-page-popup .mk-editor .rc-md-editor {
  height: 100%;
  width: 100%;
}

.popup-container.add-page-popup
  .mk-editor
  .rc-md-editor
  .rc-md-navigation
  .button-wrap
  .button {
  margin-left: unset;
  margin-right: unset;
  padding: 0 0.9rem;
}

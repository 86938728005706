
.area-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}


.area-container.grow-vertically {
    width: 100%;
}

.area-container.grow-horizontally {
    height: 100%;
}

.spaces-page {
  display: flex;
  flex-direction: column;
  gap: 2.7143rem;
  padding: 0 3.5714rem 3.5714rem 3.5714rem;
}

.spaces-page .page-header {
  display: flex;
  justify-content: space-between;
  min-height: 4.393rem;
  padding-top: 2.021rem;
  align-items: center;
}

.spaces-page .page-header .addspace-button {
  padding: 0.714rem;
  height: 2.857rem;
}

.spaces-page .cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 3.0714rem;
}


.edit-content{
    display: flex;
    width: 100%;
}


.edit-content .edit-space-side-bar{
    height: 100%;
    min-width: 19.2857rem;
    background-color: var(--grey-palette-06);
    padding: 0.95714rem;
    color:  var(--grey-palette-04);
}

.edit-content .edit-space-side-bar .edit-overview,
.edit-content .edit-space-side-bar .edit-permissions{
    padding: 0.5714rem;
    color: var(--grey-palette-02);
}


.edit-content-page-area {

    flex-grow: 1;
    height: 100%;
    position:relative;
}

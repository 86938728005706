.app-portal {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.gap {
    gap: 1.143rem
}

.notification {
    position: relative;
    right: -3.125rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #000000;
    box-shadow: 0px 0.375rem 0.9375rem 0.3125rem rgba(0, 0, 0, 0.04);
    opacity: 0;
    margin-top: 0.625rem;
    border: 2px solid;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .notification.show {
    animation: sho 0.4s ease-in-out forwards;
  }
  @keyframes sho {
    0% {
      right: -3.125rem;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }

  .notification.success {
    border-color: var(--primary-color);
  }

  .notification.error{
    border-color: var(--error-color);
  }


  .notification.hide {
    animation: hide 0.4s ease-in-out forwards;
  }
  @keyframes hide {
    0% {
      right: 0;
      opacity: 1;
    }
    100% {
      right: -3.125rem;
      opacity: 0;
    }
  }
  
  .notification .notification-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3.125rem;
    background-color: var(--main-text);

  }
  
  .notification .notification-icon.success {
    background-color: var(--primary-color); 
  }
  
  .notification .notification-icon.error {
    background-color: var(--error-color);
  }
  
  .notification .notification-icon svg {
    max-width: 1.625rem;
    max-width: 1.625rem;
  }
  
  .notification .notification-icon svg path {
    fill: #ffffff;
  }
  
  .notification .notification-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 1rem;
  }
  
  .notification .notification-info .notification-info-title {
  }
  
  .notification .notification-info .notification-info-text {
    font-size: 0.75rem;
    color: var(--light-gray);
    line-height: normal;
  }
  
  .notification .notification-close {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3.125rem;
    cursor: pointer;
    -webkit-transition: background-color 0.25s ease-in-out;
    -moz-transition: background-color 0.25s ease-in-out;
    -o-transition: background-color 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out;
  }
  
  .notification .notification-close:hover svg path {
    fill: #ffffff;
  }


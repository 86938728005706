.text-button {
    cursor: pointer;
}


.text-button.primary {
    color: var(--primary-color);
    font-weight: var(--font-weight-medium);
}


.text-button.primary:hover {
    color: var(--secondary-color);
}


.text-button.white {
    color: white;
    font-weight: var(--font-weight-medium);
}


.text-button.white:hover {
    color: var(--primary-color);
}



.text-button.disabled {
    opacity: 0.3;
}

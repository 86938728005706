.tree-header {
  display: flex;
  align-items: center;
}

.tree-header .tree-header-label {
  font-size: 1.2857rem;
  flex-grow: 1;
  padding-left: 0.5857rem;
}

.tree-header .tree-header-actions {
  display: flex;
}

.tree-header .tree-header-actions .tree-header-main-action {
  align-self: center;
  font-size: 2.1429rem;
}

.tree-header
  .tree-header-actions
  .tree-header-main-action
  .tree-action-icon
  svg {
  height: 2.1429rem;
  width: 2.1429rem;
}

.tree-header .tree-header-actions .more-actions .more-actions-icon.header {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-right: 0px;
  border-bottom: 0;
}

.tree-header .tree-header-actions .more-actions .more-actions-icon.header svg {
  height: 2.1429rem;
  width: 2.1429rem;
}

.tree-header .tree-action-icon:hover svg {
  opacity: 0.8;
  cursor: pointer;
}

.tree-body svg,
.tree-body svg path {
  fill: var(--svg-main-color);
}

.tree-item:has(.highlighted) .tree-icon svg path {
  fill: white;
}

.tree-item:not(:has(.highlighted)) .tree-icon svg path {
  fill: revert;
}

.tree-item:has(.highlighted) .tree-label {
  color: var(--text-main-color);
}

.tree-item:not(:has(.highlighted)) .tree-label {
  color: var(--grey-palette-03);
}

.tree-item-content {
  display: flex;
  min-height: 2.0714rem;
  flex-direction: row;
  align-items: center;
  padding: 0.2143rem 0.2857rem;
  user-select: none;
  cursor: pointer;
}

.tree-item-content:hover {
  background-color: var(--grey-palette-05);
}

.tree-item-content.highlighted {
  background-color: var(--grey-palette-05);
}

.tree-item-content .tree-icon svg {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tree-item-content .tree-icon {
  display: flex;
}

.tree-item-content.highlighted .tree-icon svg path {
  fill: white;
}

.tree-item-content .tree-expand {
  cursor: pointer;
  /* z-index: 999; */
  display: flex;
}

.tree-item-content .tree-expand:hover {
  opacity: 0.8;
}

.tree-item-content .tree-label {
  flex-grow: 1;
  font-size: 0.9571rem;
  padding-left: 0.2857rem;
  color: var(--grey-palette-03);
}

.tree-item-content.highlighted .tree-label {
  color: var(--text-main-color);
}

.tree-item-content .tree-actions {
  display: flex;
}

.tree-item-children {
  padding-left: 0.7143rem;
}

.item-main-action {
  font-size: 1.5714rem;
}

.item-main-action.highlighted:hover {
  cursor: pointer;
  opacity: 0.8;
}

.item-main-action.highlighted svg path {
  fill: white;
}

.more-actions {
  position: relative;
}

.more-actions .more-actions-icon {
  display: flex;
  justify-content: center;
  align-content: center;
}

.more-actions .more-actions-icon svg {
  height: 1.5714rem;
  width: 1.5714rem;
}

.more-actions .more-actions-icon.highlighted path {
  fill: white;
}

.more-actions .more-actions-icon:hover {
  cursor: pointer;
  opacity: 0.8;
}

.more-actions .more-actions-panel {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  width: max-content;

  background-color: white;
  z-index: 999;
}

.more-actions .more-actions-panel .item-other-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0;
  padding: 0.357rem 0.857rem;
}
.more-actions .more-actions-panel .item-other-action:hover {
  cursor: pointer;
  background-color: var(--grey-palette-04);
}

.more-actions .more-actions-panel .item-other-action .action-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-actions .more-actions-panel .item-other-action:hover .action-icon path {
  fill: var(--primary-color);
}

.more-actions .more-actions-panel .item-other-action .action-label {
  color: black;
  font-size: 0.855rem;
}

.more-actions .more-actions-panel .item-other-action:hover .action-label {
  color: var(--primary-color);
}



.tree-item .icon-button .icon  {
  height: 22px;
  width: 22px;
}

.spacer {

}

.spacer.horizontal {
    width: 1.143rem;
}

.spacer.vertical {
    height: 1.143rem;
}

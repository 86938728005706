.card-container {
  background: #272628;
  border: 1px solid #363838;
  padding: 1.5714rem;
}





.card-container.clickable:hover {
  background-color: var(--grey-palette-05);
  cursor: pointer;
}

@import "colors.css";

@import url('./fonts/poppins/poppins.css');

:root {

    --font-family: "Poppins";

    --font-weight-thin: 100;
    --font-weight-light: 300;
    --font-weight-regular: 400;

    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --font-weight-extrabold: 800;
}


h1, h2, h3, h4, .subtitle, body, caption, .caption, input {
    color: var(--text-main-color);
    font-family: var(--font-family), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

h1 {
    font-size: 1.714rem;
    font-weight: var(--font-weight-semibold);
}

h2 {
    font-size: 1.429rem;
    font-weight: var(--font-weight-semibold);
}

h3 {
    font-size: 1.286rem;
    font-weight: var(--font-weight-semibold);
}

h4, .subtitle {
    font-size: 1.142rem;
    font-weight: var(--font-weight-medium);
}


html, body{
    font-size: 14px;
    font-weight: var(--font-weight-regular);
}

input {
    font-size: 1rem;
    font-weight: var(--font-weight-regular);
    caret-color: var(--text-main-color);
}

caption, .caption {
    font-size: 0.857rem;
    font-weight: var(--font-weight-regular);
}

ul {
    padding-left: 2rem;
}

pre {
    /* background-color: #353535; */
    display: block;
    /* padding: 20px; */
    line-height: 23px;
    /* word-break: break-word; */
    margin-top: 1rem;
}

s {
    text-decoration: line-through;
}

blockquote {
    position: relative;
    margin: 16px 0;
    padding: 5px 8px 5px 30px;
    background: none repeat scroll 0 0 rgba(102,128,153,.05);
    color: inherit;
    border: none;
    border-left: 10px solid #d6dbdf;
}

table th {
    text-align: center;
    font-weight: 700;
    border: 1px solid var(--grey-palette-03);;
    padding: 10px 6px;
    background-color: #353535;
    word-break: break-word;
  }

table td {
    border: 1px solid var(--grey-palette-03);
    text-align: left;
    padding: 10px 15px;
    word-break: break-word;
    min-width: 60px;
}

a {
    color: var(--primary-color);
}

.popup-container.history-popup {
  display: flex;
  flex-direction: column;
  min-width: 95vw;
  height: 90vh;
}

.popup-container.history-popup .history-popup-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popup-container.history-popup .line-separator {
  margin-top: 1.4286rem;
  margin-bottom: 0;
}

.popup-container.history-popup .history-popup-header .icon-close-popup path {
}

.popup-container.history-popup .history-popup-container {
  display: flex;
  height: 0px;
  width: 100%;
  flex-grow: 1;
}

.popup-container.history-popup .history-popup-container.empty {
  justify-content: center;
  align-items: center;
}

.popup-container.history-popup .history-popup-container .history-versions {
  overflow-y: auto;
  overflow-x: hidden;
}

.popup-container.history-popup
  .history-popup-container
  .history-versions
  .version {
  display: flex;
  align-items: center;
  width: 16rem;
  height: 5.3571rem;
  padding-left: 1.0714rem;
  cursor: pointer;
  font-size: 0.8571rem;
  font-weight: var(--font-weight-medium);
}

.popup-container.history-popup
  .history-popup-container
  .history-versions
  .version.selected,
.popup-container.history-popup
  .history-popup-container
  .history-versions
  .version:hover {
  background-color: var(--grey-palette-05);
}

.popup-container.history-popup .history-popup-container .history-main {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.popup-container.history-popup .history-popup-container .history-main-content {
  display: flex;
  width: 100%;
  height: 100%;
}


.popup-container.history-popup
  .history-popup-container
  .history-main
  .content-column {
  padding: 1.4286rem 1.4286rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  font-size: 0.857rem;

}

.popup-container.history-popup
  .history-popup-container
  .history-main
  .content-column:first-child {
  background-color: var(--grey-palette-05);
}

.comment {
  gap: .7143rem;
  margin-bottom:.7143rem;
}


.comment .comment-profile-icon {
  min-width: 2.8571rem;
  min-height: 2.8571rem;
  background-color: var(--secondary-color);
  border-radius: 2.8571rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1429rem;
  font-weight: 500;
}

.comment .comment-header {
  gap: .5714rem;
  margin-bottom: .1429rem;
}

.comment .comment-header .profile-name {
  font-size: .8571rem;
  font-weight: 600;
}

.comment .comment-header .comment-date {
  font-size: .7143rem;
  font-weight: normal;
  color: var(--grey-palette-02);
}

.comment .comment-description {
  font-size: .8571rem;
  margin-bottom: .2857rem;
}

.comment .comment-actions {
  gap: .2857rem;
}

.comment:hover .comment-actions .dot {
  border: 1px solid #B9BEBE;
}

.comment .comment-actions .dot {
  width: 2px;
  height: 2px;
  opacity: 1;
}

.comment:hover .comment-actions .comment-action-button {
  color: var(--primary-color);

}

.comment .comment-actions .comment-action-button {
  color: transparent;
  font-size: .7143rem;

}

.comment .comment-actions .comment-action-button:hover {
  cursor: pointer;
}

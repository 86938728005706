.page-header {
    display: flex;
    flex-direction: row;


}

.page-header h1 {
    font-size: 2.143rem;
}


.page-header .page-header-details{
    display: flex;
    flex-direction: column;
    flex-grow:1;

}





.page-header .page-header-details-subinfo {

}


.page-header .page-header-actions{
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
}

.page-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

}





.page-scroll {
    position: absolute;
    bottom:0;
    top:0;
    left:0;
    right:0;
    overflow-y: auto;
}


.page-no-scroll {
    position: inherit;
    bottom: inherit;
    top: inherit;
    left: inherit;
    right: inherit;
    overflow-y: unset;
}

.detailed-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23.5714rem;
  min-height: 12.2857rem;
  padding-bottom: 1rem;
}

.detailed-card .detailed-card-info {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.detailed-card .detailed-card-header {
  display: flex;
  align-items: center;
  gap: 1.1429rem;
}

.detailed-card .detailed-card-header .detailed-card-avatar {
  min-width: 3.4286rem;
  height: 3.4286rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient-main-135deg);
}

.detailed-card .detailed-card-header h3 {
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.detailed-card .detailed-card-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.detailed-card .detailed-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.8571rem;
}

.detailed-card .detailed-card-footer .detailed-card-footer-label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.detailed-card .detailed-card-footer .detailed-card-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailed-card .detailed-card-footer .detailed-card-button:hover {
  background-color: var(--grey-palette-05);
  cursor: pointer;
}

.detailed-card .detailed-card-footer .detailed-card-button svg path {
  fill: var(--text-main-color);
}

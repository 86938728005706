.right-side-bar {
  height: 100%;
  width: 4.7143rem;
  justify-content: space-between;
  border-right: 2px solid #363838;
}



.right-side-bar .button-height{
  height: 4.7143rem;
  font-size: 2.1429rem;
}
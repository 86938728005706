* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}


.app-portal .main-content{
  display: flex;
 flex-grow:1;
 /* padding: 0 3.5714rem 0 3.5714rem; */
 width:100%;
 height: 100%;
}

.app-portal .main-content .content{
  display: flex;
  flex-grow:1;
  height: 100%;
  position: relative;
  /* padding: 0 3.5714rem 0 3.5714rem; */
}

.app-portal .main-content .sidebar-content{
  display: flex;
  color: rgb(77, 31, 31);
  height: 100%;
}


.scroll-parent {
  position: relative;
}

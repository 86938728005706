.popup-area {
    width: 90vw;
    height: 90vh;
    max-width: 90vw;
    max-height: 90vh;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-area .popup-area-mouse-events{
  /* width: 100%;
  height: 100%; */
  min-height: 0;
}

.popup-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .popup-container .popup-center {
    width: 90vw;
    height: 90vh;
    max-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0px 0.375rem 0.9375rem 0.3125rem rgba(0, 0, 0, 0.04);
    border-radius: 0.625rem;
    padding: 2.5rem;
    cursor: default;
    overflow: auto;
} */

  /* @media only screen and (max-width: 768px) {
    .popup-container {
      height: 100vh;
    }

    .popup-container .popup-center {
      padding: 1.5rem;
    }
  }

  @media only screen and (max-width: 425px) {
    .popup-container .popup-center {
      padding: 1rem;
    }
  } */

.text-input {
    display: flex;
    align-items: center;
    height: 2.857rem;
    min-width: 14.286rem;
    border: 1px solid var(--border-main-color);
    padding: 0 0.571rem;
    pointer-events: auto;
}

.text-input:hover,
.text-input:focus-within {
    border-color: var(--primary-color);
}

.text-input.error,
.text-input.error:hover {
    border-color: var(--error-color);
}

.text-input.disabled,
.text-input.disabled:hover {
    border-color: var(--bg-disabled-color);
    background-color: var(--bg-disabled-color);
}

.text-input .native-input {
    flex-grow: 1;
    padding: 0;
    padding-left: 0.286rem;
    caret-color: var(--text-main-color);
    border: none;
    background-image:none;
    background-color:transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.text-input.has-icon .native-input{
    padding-left: 0.429rem;
}

.text-input .native-input:focus {
    outline:none;
}

.text-input .native-input::placeholder {
    color: var(--placeholder-color);
}

.text-input.disabled .native-input {
    color: var(--text-disabled-color);
}

.text-input.disabled .native-input::placeholder {
    color: var(--placeholder-disabled-color);
}

.text-input .text-input-icon {
    display: flex;
    align-items: center;
}

.text-input:hover .text-input-icon svg,
.text-input:focus-within .text-input-icon svg {
    fill: var(--primary-color);
}

.text-input.error .text-input-icon svg,
.text-input.error:hover .text-input-icon svg,
.text-input.error:focus-within .text-input-icon svg {
    fill: var(--error-color);
}

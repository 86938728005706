.page-content-renderer-area {
    display: flex;
    flex-direction: column;
}


.page-content-renderer {

    display: flex;
    text-align: justify;
    line-height: 40px;
}


.page-content-renderer .page-content-renderer-body-empty {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.page-content-renderer .page-content-renderer-body {
    flex-grow: 1;
}






.page-content-renderer table,
.page-content-renderer th,
.page-content-renderer td {
    border-collapse: collapse;

}



.page-content-renderer p,
.page-content-renderer summary{
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 23px;
}


.page-content-renderer h1,
.page-content-renderer h2,
.page-content-renderer h3,
.page-content-renderer h4,
.page-content-renderer h5,
.page-content-renderer h6,
.page-content-renderer li,
.page-content-renderer p
{
    overflow-wrap: break-word;
}

.page-content-renderer h1,
.page-content-renderer h2
{
    margin: 48px 0 16px;
}



.page-content-renderer h3
{
    margin: 36px 0 16px;
}


.page-content-renderer ul,
.page-content-renderer ol {
    line-height: 2rem;
}


body { scroll-behavior: smooth; }


.page-content-renderer ol { counter-reset: list-item;     line-height: 1.6em; }
/* .page-content-renderer ol li { display: block; counter-increment: list-item; display: flex; flex-direction: row;} */
.page-content-renderer ol li { display: block; counter-increment: list-item;}
.page-content-renderer ol li:before { content: counters(list-item,'.') '. ';  min-width: 13px; flex-direction: row; margin-right: 3px; color: #b9bebe; }



.page-content-renderer table td,
.page-content-renderer table th{

    padding: 5px;
    line-height: 1.5em;
}


.page-content-renderer ul{


    list-style-type: disc;
    line-height: 1.6em;

}

.page-content-renderer ul li::marker {
    color: #b9bebe;
}

.page-content-renderer table th {

    border: 1px solid var(--grey-palette-02);;

  }

.page-content-renderer table td {
    border: 1px solid var(--grey-palette-02);
}


.page-content-renderer h1 a,
.page-content-renderer h2 a,
.page-content-renderer h3 a,
.page-content-renderer h4 a,
.page-content-renderer h5 a,
.page-content-renderer h6 a {
    color:inherit;
}

.page-content-renderer h1 a:visited,
.page-content-renderer h2 a:visited,
.page-content-renderer h3 a:visited,
.page-content-renderer h4 a:visited,
.page-content-renderer h5 a:visited,
.page-content-renderer h6 a:visited {
    color:inherit;
}


.page-content-renderer img {
    max-width: 100%;
}



.page-content-renderer .full-text-length {
    white-space:nowrap;
}


.page-content-renderer figcaption {
    text-align: center;
    font-style: oblique;
    line-height: 1.5rem;
}


.page-content-renderer figure {
    margin-bottom: 3rem;
}


@import "react-markdown-editor-lite/lib/index.css";

@import "default.css";

@import "an-old-hope.css";


.rc-md-editor,
.rc-md-navigation {
  border: 0 !important;
  border-bottom: 0 !important;
  background: inherit !important;
}

.editor-container .section-container.input  {
  border: 0 !important;
  border-bottom: 0 !important;
  background: inherit !important;
  color: #fff !important
}

.editor-container > .section {
  border-right: 1px solid var(--grey-palette-03) !important;
}

.editor-container>.section:last-child {
  border-right: 0 !important;
}



.custom-html-style {
  color: inherit!important;
}

.custom-html-style code, .custom-html-style pre {
  background-color: #353535 !important;
}

.custom-html-style blockquote {
  color: inherit !important;
}

.custom-html-style table th {
  text-align: center !important;
  font-weight: 700 !important;
  border: 1px solid var(--grey-palette-03) !important;
  padding: 10px 6px !important;
  background-color: #353535 !important;
  word-break: break-word !important;
}

.custom-html-style table td {
  border: 1px solid var(--grey-palette-03) !important;
  text-align: left !important;
  padding: 10px 15px !important;
  word-break: break-word !important;
  min-width: 60px !important;
}

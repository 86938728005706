.popup-container.system-popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 25.571rem;
}


.popup-container.system-popup .popup-icon {
    display: flex;
}


.popup-container.system-popup .popup-header {
   color: white;
   font-weight: var(--font-weight-semibold);
   font-size: 1.286rem;
}



.popup-container.system-popup .popup-content {
    color: rgba(255,255,255, 0.4);
    font-weight: var(--font-weight-regular);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}



.popup-container.system-popup .popup-actions {
    display: flex;
    width: 100%;
    flex-grow: 1;
    gap: 1rem;
}

.popup-container.system-popup .popup-actions .button{
    flex-grow: 1;
    flex-basis: 0;
}

.popup-container.dropzone-popup {
  display: flex;
  flex-direction: column;
  min-width: 95vw;
  height: 90vh;
}

.popup-container.dropzone-popup .dropzone-popup-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popup-container.dropzone-popup .dropzone-popup-container {
  display: flex;
  height: 100%;
  flex-grow: 1;
  overflow-y: scroll;
}

.popup-container.dropzone-popup .dropzone-popup-container .dropzone-files{
    width: 100%;
    height: 100%;
    border-style: dashed;
    border-width: 5px;
    border-color: aqua;
    border-radius: 10px;
    color: aqua;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

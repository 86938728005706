.side-bar {
  display: flex;
  height: 100%;
  width: 5.7143rem;
  background-color: var(--grey-palette-06);
  flex-direction: column;
  justify-content: space-between;
  border-right: 2px solid #363838;
}

.side-bar .buttons-height {
  height: 5.7143rem;
  font-size: 3.071rem;
}

.popup-container.at-popup {
    display: flex;
    flex-direction: column;
    min-width: 95vw;
    height: 90vh;
  }
  
  .popup-container.at-popup .at-popup-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .popup-container.at-popup .line-separator {
    margin-top: 1.4286rem;
    margin-bottom: 0;
  }

  .popup-container.at-popup .at-popup-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: auto;
  }

  .popup-container.at-popup .at-popup-container .at-answer {
    margin-top: 2.1429rem;
    display: flex;
    height: max-content;
    text-align: justify;
    white-space: pre;
    width: 90vw;
    font-size: 1.2rem;
  }
.page-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  flex-grow: 1;
}


.page-area {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}
/* .page-content .empty-state {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 20rem;
} */

.page-content .page-content-body {
  padding: 2.0357rem 3.571rem ;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.page-content-wrapper{
  max-width: 1000px;
  width:100%;
  display: flex;
  flex-direction: column;
}

.page-area .panel-1{
  scroll-behavior: smooth;
}

.page-area .panel-2 {
  display: flex;
  flex-direction: column;
  min-width: 25.1429rem;
  max-width: 25.1429rem;
  justify-content: space-between;
  border-left: .1429rem solid #363838;
}

.page-area .page-content-comments-container{
  padding: 1.6429rem 1.5714rem;

}

.page-area .panel-3 {
  display: flex;
  height: 100%;
  flex-direction: column;
  border-left: 2px solid #363838;
}

.page-content .page-content-container {
  display: flex;
  flex-direction: column;
}

.icon-button-nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-button-nav-link .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-button-nav-link .icon svg {
  width: 100%;
  height: 100%;
}

.icon-button-nav-link:hover .icon svg path {
  fill: var(--grey-palette-04);
}

.icon-button-nav-link a.selected .icon  svg path {
  fill: var(--grey-palette-04);
}



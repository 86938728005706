.edit-space-page {
    padding: 2rem;
    gap: 2rem;
}

.edit-space-page .edit-input-area {
    gap: 0.7143rem;
    flex-direction: column;
    display: flex;
    padding: 0;
}


.edit-space-page .act-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;

}


.edit-space-page .act-btns .action-delete {
    color: var(--error-color);
    width: 7.1429rem;
    cursor: pointer;
}

.edit-space-page .act-btns .action-delete:hover {
    opacity: 0.8;
}

.edit-space-page .act-btns .action-save {
    display: flex;

    justify-content: space-between;
}
.flex-layout {
    display: flex;
}

.flex-layout.horizontal {
    flex-direction: row;
}

.flex-layout.vertical {
    flex-direction: column;
}

.flex-layout.horizontal.horizontal-start {
    justify-content: start;
}

.flex-layout.horizontal.horizontal-center {
    justify-content: center;
}

.flex-layout.horizontal.horizontal-end {
    justify-content: end;
}


.flex-layout.horizontal.vertical-start {
    align-items: start;
}

.flex-layout.horizontal.vertical-center {
    align-items: center;
}

.flex-layout.horizontal.vertical-end {
    align-items: end;
}



.flex-layout.vertical.horizontal-start {
    align-items: start;
}

.flex-layout.vertical.horizontal-center {
    align-items: center;
}

.flex-layout.vertical.horizontal-end {
    align-items: end;
}


.flex-layout.vertical.vertical-start {
    justify-content: start;
}

.flex-layout.vertical.vertical-center {
    justify-content: center;
}

.flex-layout.vertical.vertical-end {
    justify-content: end;
}

.page-container.overview-page {
  display: flex;
  padding: 0 0 0 3.5714rem;
}

.page-container.overview-page .overview-space-title {
  font-size: 4.2857rem;
  font-weight: var(--font-weight-semibold);
}

.page-container.overview-page .description-container {
  display: flex;
  flex-wrap: wrap;
  height: 30vh
}

.page-container.overview-page .description-container .column {
  flex: 1 0 50%;
  
}


.state {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 2rem;
    flex-direction: column;
}


.state .state-ilustration svg {
    font-size: 20rem;

}

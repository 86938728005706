.logo-text {
  background: linear-gradient(
    101deg,
    var(--primary-color) 0%,
    var(--secondary-color) 100%
  );
  background-size: 100%;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 4rem;
  text-align: center;

  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

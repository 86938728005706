.loader-container {
  position: relative;
  height: 6.25rem;
  width: 6.25rem;
  display: contents;
}

.loader {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    border-radius:100%;
    opacity:0;
    border:1px solid var(--primary-color);
    animation:anim 4s infinite linear;
  }
  
  @keyframes anim {
    0% {
      opacity:1;
      width:0vmin;
      height:0vmin;
    }
    100% {
      opacity:0.0;
      width:20vmin;
      height:20vmin;
    }
  }
  
  .loader:nth-child(1) { animation-delay:666ms; }
  .loader:nth-child(2) { animation-delay:1333ms; }
  .loader:nth-child(3) { animation-delay:1999ms; }
  .loader:nth-child(4) { animation-delay:2665ms; }
  .loader:nth-child(5) { animation-delay:3334ms; }
  .loader:nth-child(6) { animation-delay:4000ms; }
  
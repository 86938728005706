.popup-container.media-popup {
  display: flex;
  flex-direction: column;
  min-width: 95vw;
  height: 90vh;
}

.popup-container.media-popup .media-popup-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popup-container.media-popup .line-separator {
  margin-top: 1.4286rem;
  margin-bottom: 0;
}


.popup-container.media-popup .media-popup-container {
  display: flex;
  height: 100%;
  flex-grow: 1;
  overflow-y: scroll;
}
.popup-container.media-popup .media-popup-container .media-files{
  margin-top: 2.1429rem;
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  height: max-content;
}

.popup-container.media-popup .media-popup-container .media-files-drop-here{
  height: 200px;
  width: 200px;
  border: 5px dashed aqua;
  border-radius: 10px;
  color: aqua;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

.popup-container.media-popup .media-popup-container .media-file {
  display: flex;
  flex-direction: column;
  background-color: #3b3a3d;
  border-radius: 20px;
  height: fit-content;
  width: 200px;
  
}

.popup-container.media-popup .media-popup-container .media-file .media-file-label {
  word-break: break-all;
  margin: 10px;
}

.popup-container.media-popup .media-popup-container .media-file .media-file-date {
  color: #aeaab4;
  
}
.popup-container.media-popup .media-popup-container .media-file:hover {
  cursor: pointer;
  background-color: #48464b;
}
.popup-container.media-popup .media-popup-container .media-file img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 20px;
}

.popup-container.media-popup .media-popup-container .media-file svg {
  width: 200px;
  height: 150px;
  margin-top: 20px;
}

.popup-container.media-popup .media-popup-container .media-file:hover img {
  opacity: 0.8;
}

.popup-container.media-popup .media-popup-container.empty {
  justify-content: center;
  align-items: center;
}


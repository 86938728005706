.documentation-page {
    display: flex;
    flex-direction: row;
}


.documentation-page .documentation-page-tree-area {
    min-width: 19.714rem;
    max-width: 30%;
    height: 100%;
    background-color: var(--grey-palette-06);
    border-right: 2px solid #363838;
    overflow:auto;
}

.documentation-page .documentation-page-tree-area  .tree{
    padding-top: 1.125rem;
}



.documentation-page .documentation-page-content-area {
    flex-grow: 1;
    height: 100%
}



.test {
    fill: var(--primary-color)
}

.page-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  flex-grow: 1;
}


.page-area {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

.page-container.doubts-page {
  padding: 2.0357rem 3.571rem ;
  scroll-behavior: smooth;
}


  .page-container.doubts-page .doubts-add-textInput {
    width: 95%;
  }

  .page-container.doubts-page .doubts-buttons {
    flex-direction: row;
    display: flex;
  }
  .page-container.doubts-page .doubts-add-button {
    width: 20%;
  }
  .page-container.doubts-page .doubts-update-chat {
    width: 20%;
  }

  .page-container.doubts-page .doubts-answer {
    display: flex;
    height: max-content;
    text-align: justify;
    white-space: pre;
  }

  
  .page-content .doubts-answer-body {
    padding: 2.0357rem 3.571rem ;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  
  .doubts-answer-wrapper{
    max-width: 1000px;
    width:100%;
    display: flex;
    flex-direction: column;
  }
.nav-bar-app-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 5.7143rem;
    left: 0;
    background: var(--gradient-main-135deg);
    z-index: 9999;
    min-width: 10rem;
    padding: 2rem;
    
}

.nav-bar-app-menu .nav-bar-btn {
    padding: 1.2rem;
}
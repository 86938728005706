:root {
  --bg-main-color: #141315;
  --bg-disabled-color: var(--white-transparency-01);

    --primary-color:  #5BCEDD;
    --primary-color-transparency-02: rgba(91,206,221,0.2);
    --secondary-color: #6020B7;
  --primary-color: #5bcedd;
  --secondary-color: #6020b7;

  --gradient-main: transparent
    linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%)
    0% 0% no-repeat padding-box;
  --gradient-main-135deg: transparent
    linear-gradient(
      135deg,
      var(--primary-color) 0%,
      var(--secondary-color) 100%
    )
    0% 0% no-repeat padding-box;

  --success-color: #2db273;
  --error-color: #f5525f;
  --warning-color: #edbd63;

  --grey-palette-01: #101010;
  --grey-palette-02: #787c7c;
  --grey-palette-03: #b9bebe;
  --grey-palette-04: #f2f8f8;
  --grey-palette-04-alpha05: #f2f8f880;
  --grey-palette-05: #363838;
  --grey-palette-06: #272628;

  /* Label disabled */
  --white-transparency-01: rgba(255, 255, 255, 0.1);
  --white-transparency-02: rgba(255, 255, 255, 0.2);

  --placeholder-color: var(--grey-palette-02);
  --placeholder-disabled-color: rgba(120, 124, 124, 0.5);

  --border-main-color: var(--grey-palette-02);
  --border-table-cell: var(--grey-palette-05);

  /* --text-main-color: #ffffff;*/
  --text-main-color: rgba(255,255,255,0.85);
  --text-disabled-color: var(--grey-palette-02);



  --text-hover-color: var(--primary-color);
  --text-error-color: var(--error-color);

  --svg-main-color: var(--grey-palette-02);

  --separator-bg: var(--grey-palette-05);
}

body {
  background-color: var(--bg-main-color);
}

/* svg,
svg path {
    fill: var(--svg-main-color)
} */

.button {
    padding: 0.714rem 1.429rem;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button .button-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button .button-icon path{
    fill:white;
}

.button.primary {
    color: white;
    font-weight: var(--font-weight-medium);
    background: var(--gradient-main);
}


.button.primary:hover {
    background: var(--secondary-color);
}



.button.secondary{
    color: var(--primary-color);
    background: transparent;
    box-shadow: inset 0 0 0 1px var(--primary-color);
}


.button.secondary:hover {
    background: var(--secondary-color);
}


.button.tertiary {
    color: white;
    font-weight: var(--font-weight-medium);
    background: var(--secondary-color);
}


.button.tertiary:hover {
    opacity: 0.8;
}



.button.white{
    color: white;
    background: transparent;
}


.button.white:hover {
    background: transparent;
    color: var(--primary-color);
}



.button.disabled {
    opacity: 0.3;
}

.form-field{
    display: flex;
    flex-direction: column;
    gap: 0.429rem;
    pointer-events: none;
}

.form-field .form-field-label {
    font-size: 1rem;
    pointer-events: none;
}

.form-field .form-field-error {
    color: var(--error-color);
    pointer-events: none;
}

.form-field:hover .form-field-label,
.form-field:focus-within .form-field-label{
    color: var(--primary-color);
}

.form-field.disabled .form-field-label {
    color: var(--white-transparency-02);
}

.form-field.error .form-field-label,
.form-field.error:focus-within .form-field-label{
    color: var(--error-color);
}

.add-space-popup {
    display: flex;
    flex-direction: column;
    min-width: 30rem;
    min-height: 10rem;
  }
  
  .add-space-popup .inputs {
    padding: 0;
  }
  
  .add-space-popup .separator {
    padding: 1.1429rem 0 0 0;
    border-top: 0.0625rem solid var(--grey-palette-02);
  }
  
  .add-space-popup .act-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .add-space-popup .act-btns .text-button.white {
    padding-right: 2rem;
  }
  
  .add-space-popup .act-btns .button.primary {
    width: 7.1429rem;
    height: 2.8571rem;
  }
  